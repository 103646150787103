
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "house-confirmation-of-cover",
  components: { GoBackButton },
  data() {
    return {
      publicId: this.$route.params.publicId,
      isVisible: false,
      summary: {},
      logoUrl: ""
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Confirmation of Cover", [
        "Policy",
        "Policy Information",
        "House Insurance"
      ]);
    });
  },
  created() {
    const router = useRouter();

    // Redirect back to policy details if publicId does not exist
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get the details of the vehicle in schdule list
    this.getVehicleDetails();

    this.logoUrl = variables.UNDERWRITER_LOGO_ROUTE;

    //Set page title
    document.title =
      "House Insurance Confirmation Of Cover | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    printPage(el) {
      variables.printPage(el);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getVehicleDetails() {
      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          variables.POLICIES_ROUTE + "/folio-item/" + this.publicId
        )
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.summary = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    }
  }
});
